import React from 'react'
import styled from 'styled-components'
import {
  Routes,
  Route,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import Overview from './overview'
import Settings from './settings'
import ChangeLog from './changelog'
import Change from './change'
import { Loader, Button, View } from '../ui'
import useGetProject from './useGetProject'
import { useAuth } from '../../hooks'

//#region styles
const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  ${p => p.theme.layout.web} {
    flex-direction: row;
  }
`

const TabLink = styled(NavLink)`
  min-height: 68px;
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  text-transform: uppercase;
  color: ${p => p.theme.color.text};
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin: 0 1rem;
  padding-top: 5px;
  border-bottom: 2px solid transparent;
  z-index: 1;
  transition: border-bottom-color 200ms, color 200ms;

  &:hover {
    border-bottom-color: #eee;
  }

  &.tab--active {
    color: ${p => p.theme.color.primary};
    border-bottom-color: ${p => p.theme.color.primary};
    &:hover {
      border-bottom-color: #555;
    }
  }

  ${p => p.theme.layout.web} {
    margin: 0 1rem 0 2rem;
  }
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding-right: 1rem;
  ${p => p.theme.layout.web} {
    padding-right: 2rem;
  }
`
//#endregion

const Project = () => {
  const { id } = useParams()
  const loc = useLocation()
  const navigate = useNavigate()
  const { project, isFetching, error } = useGetProject(id)
  const { user } = useAuth()

  // Prevent persisting values after project created and redirected
  if (localStorage.getItem('create-project-clear')) {
    localStorage.removeItem('create-project')
    localStorage.removeItem('create-project-clear')
  }

  if (error) {
    navigate('/projects')
    return null
  }

  const canViewSettings =
    user.isProjectManager || user.isCostManager || user.user_group === 'client'
  const canEditSettings = user.isProjectManager || user.isCostManager
  const canCreateCR =
    user.isProjectManager ||
    user.isCostManager ||
    user.isProjectMember ||
    user.user_group === 'client'

  return (
    <Loader isFetching={isFetching}>
      {project ? (
        <>
          <FlexRow>
            <Nav>
              <TabLink to="overview" activeClassName="tab--active">
                Overview
              </TabLink>
              <TabLink
                to="changes"
                activeClassName="tab--active"
                className={
                  loc.pathname.includes('changes') ? 'tab--active' : ''
                }
              >
                Change Log
              </TabLink>
              {canViewSettings ? (
                <TabLink to="settings" activeClassName="tab--active">
                  Project Settings
                </TabLink>
              ) : null}
            </Nav>

            {canCreateCR ? (
              <Button onClick={() => navigate('changes/new')}>
                New Change Request
              </Button>
            ) : null}
          </FlexRow>
          <View>
            <Routes>
              <Route
                path="/*"
                element={<Overview id={project.id} project={project} />}
              />
              <Route path="changes" element={<ChangeLog />} />
              <Route
                path="changes/:cr_id"
                element={
                  <Change
                    projectId={project.id}
                    orgId={project.org_id}
                    currency={project.currency}
                  />
                }
              />
              {canViewSettings ? (
                <Route
                  path="settings"
                  element={<Settings canEdit={canEditSettings} />}
                />
              ) : null}
            </Routes>
          </View>
        </>
      ) : null}
    </Loader>
  )
}

export default Project
