import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query-devtools'
import { ReactQueryCacheProvider, QueryCache } from 'react-query'

import { Toaster } from './components/ui'
import AuthProvider from './context/auth'
import theme, { GlobalStyle } from './style/theme'
import './style/index.css'

const queryCache = new QueryCache()

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ReactQueryDevtools />
      <GlobalStyle />
      <Toaster>
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </Toaster>
    </ReactQueryCacheProvider>
  </ThemeProvider>,
  document.getElementById('root'),
)
