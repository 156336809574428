import React from 'react'
import PropTypes from 'prop-types'
// import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Form } from '../form'
import ProjectDetails from './details'
import { ClientBudget, ClientContacts, ClientDetails } from './client'
import CostInformation from './cost-info'
import ProjectTeam from './project-team'
import CrTimeframe from './cr_timeframe'
import Archive from './edit/archive'
import { Button } from '../ui'

// const TOAST_ERROR = { appearance: 'error', autoDismiss: true }

const FormAction = styled.div`
  display: flex;
  justify-content: ${p => (p.$edit ? 'center' : 'unset')};
`

const FormGroup = styled.div`
  background: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: ${p => p.theme.shadow};

  ${p => p.theme.layout.web} {
    padding: 2rem;
  }
`

const ProjectSettings = ({
  children,
  values,
  isSubmitting,
  canArchive,
  setFieldValue,
}) => {
  const edit = !!values.id
  const { unit } = values.measurement
  const { gross_internal, net_internal } = values.area
  const areaToUse =
    unit === 'area.gross_internal'
      ? gross_internal
      : unit === 'area.net_internal'
      ? net_internal
      : null

  const area = parseFloat(areaToUse)

  return (
    <Form autoComplete="off">
      {/* 1 - Project Details */}
      <ProjectDetails />

      <FormGroup>
        {/* 2A - Client Details */}
        <ClientDetails />
        {/* 2B - Client Contacts */}
        <ClientContacts
          newClients={values.clients}
          existingClients={values.existingClients}
          setField={setFieldValue}
          projectId={values.id}
        />
      </FormGroup>

      {/* 3 - Client Budget */}
      <FormGroup>
        <ClientBudget area={area} budget={values.budget.total} />
      </FormGroup>

      {/* 4 - Starting Costs and Risks */}
      <CostInformation
        area={area}
        costs={values.cost}
        otherCosts={values.other_costs}
      />

      {/* 5 - Change Request Timeframe Targets */}
      <CrTimeframe />

      {/* 6 - Project Team */}
      <ProjectTeam
        newTeam={values.team}
        existingTeam={values.existingTeam}
        setField={setFieldValue}
        projectId={values.id}
      />

      {/* 7 - Archive */}
      {canArchive ? <Archive id={values.id} /> : null}

      <FormAction $edit={edit}>
        <Button tall type="submit" disabled={isSubmitting}>
          {edit ? 'Save Changes' : 'Create Project'}
        </Button>
      </FormAction>

      {children}
    </Form>
  )
}

ProjectSettings.defaultProps = {
  children: null,
}

ProjectSettings.propTypes = {
  children: PropTypes.node,
  values: PropTypes.any.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  canArchive: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default ProjectSettings
