import * as Yup from 'yup'

const projectSchemaAreaError = 'Required Gross Internal or Net Internal'
//#region validations
const dashboard = Yup.object().shape({
  title: Yup.string().required('Required').default(''),
})
const site = Yup.object().shape({
  address_1: Yup.string().default('').required('Required'),
  address_2: Yup.string().default(''),
  address_3: Yup.string().default(''),
  address_towncity: Yup.string().default('').required('Required'),
  address_postcode: Yup.string().default('').required('Required'),
  address_region: Yup.string().default('').required('Required'),
  address_country: Yup.string().default('').required('Required'),
})
const client = Yup.object().shape({
  address_1: Yup.string().default('').required('Required'),
  address_2: Yup.string().default(''),
  address_3: Yup.string().default(''),
  address_towncity: Yup.string().default('').required('Required'),
  address_postcode: Yup.string().default('').required('Required'),
  address_region: Yup.string().default('').required('Required'),
  address_country: Yup.string().default('').required('Required'),
})
const clients = Yup.array().of(
  Yup.object().shape({
    name: Yup.string().default('').required('Required'),
    company: Yup.string().default('').required('Required'),
    role_title: Yup.string().default('').nullable(),
    email: Yup.string().default('').required('Required'),
    phone: Yup.string().default('').nullable(),
  }),
)
const change_request = Yup.object().shape({
  budget: Yup.object().shape({
    time: Yup.string().default('').required('Required'),
  }),
  claimed: Yup.object().shape({
    time: Yup.string().default('').required('Required'),
  }),
  assessment: Yup.object().shape({
    time: Yup.string().default('').required('Required'),
  }),
})
const team = Yup.array().of(
  Yup.object().shape({
    name: Yup.string().default('').required('Required'),
    user_type: Yup.string().default('').required('Required'),
    company: Yup.string().default('').required('Required'),
    role_title: Yup.string().default('').nullable(),
    email: Yup.string().default('').required('Required'),
    phone: Yup.string().default('').nullable(),
  }),
)

const area = Yup.object().shape({
  gross_internal: Yup.string()
    .default('')
    .test('oneOfRequired', projectSchemaAreaError, function () {
      return this.parent.gross_internal || this.parent.net_internal
    }),
  net_internal: Yup.string()
    .default('')
    .test('oneOfRequired', projectSchemaAreaError, function () {
      return this.parent.gross_internal || this.parent.net_internal
    }),
  gross_external: Yup.string().default(''),
  perimeters: Yup.string().default(''),
  typical_floor: Yup.string().default(''),
})
const measurement = Yup.object().shape({
  unit: Yup.string().default('').required('Required'),
})
const budget = Yup.object().shape({
  total: Yup.string().default('').required('Required'),
  // additional: Yup.object().shape({
  //   total: Yup.string().default('').required('Required'),
  // }),
})

const cost = Yup.object().shape({
  acquisition: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  advisor: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  archaeological: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  architect: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  building: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  cdm: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  ceiling: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  charges: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  construct: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  construction: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  contract_admin: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  contractor: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  decanting: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  design_dev: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  door: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  drainage: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  electrical: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  employer: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  employer_cr: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  employer_other: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  equipment: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  ex_walls: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  ext_works: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  facilitating: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  fees: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  finance: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  fittings: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  floor: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  floor_finish: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  frame: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  health: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  infra: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  insurance: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  interior: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  internal: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  landscape: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  main_cont: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  manager: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  marketing: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  mechanical: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  other: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  planning: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  pmanager: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  ramps: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  roof: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  specialist: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  structural: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  substructure: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  tenant: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  wall: Yup.object().shape({
    total: Yup.string().default(''),
  }),
  windows: Yup.object().shape({
    total: Yup.string().default(''),
  }),
})

Yup.addMethod(Yup.array, 'uniqueProperty', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size
  })
})

const other_costs = Yup.object().shape({
  construction: Yup.array(
    Yup.object().shape({
      type: Yup.string().required().default('').label('Cost type'),
      total: Yup.string().required().default('').label('Total'),
    }),
  )
    .uniqueProperty('Cost types must be unique', a => a.type)
    .default([]),
  project: Yup.array(
    Yup.object().shape({
      type: Yup.string().required().default('').label('Cost type'),
      total: Yup.string().required().default('').label('Total'),
    }),
  )
    .uniqueProperty('Cost types must be unique', a => a.type)
    .default([]),
  other: Yup.array(
    Yup.object().shape({
      type: Yup.string().required().default('').label('Cost type'),
      total: Yup.string().required().default('').label('Total'),
    }),
  )
    .uniqueProperty('Cost types must be unique', a => a.type)
    .default([]),
})

const currency = Yup.object().shape({
  selected: Yup.string().required('Required'),
  exchange_rate: Yup.string().default(''),
})

const contract = Yup.object().shape({
  type: Yup.string().required('Required'),
})
//#endregion

const projectSchema = Yup.object().shape({
  area,
  budget,
  client,
  clients,
  change_request,
  contract,
  cost,
  other_costs,
  currency,
  dashboard,
  measurement,
  site,
  team,
})

export default projectSchema
