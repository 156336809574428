import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'

import { AuthContext } from '../../../../context/auth'
import {
  Form,
  FormikFile,
  FormikSelect,
  FormikText,
  FormikCheckbox,
  CheckboxWithLabel,
} from '../../../form'
import { Card, Button } from '../../../ui'
import Costs from './costs'
import { changeRequestSchema } from './schema'

//#region styles
const CorCard = styled(Card)`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0.5rem 2rem;
  margin: 0;
  margin-bottom: 1rem;
  padding: 1rem;

  ${p => p.theme.layout.web} {
    gap: 1.5rem 2rem;
    margin-bottom: 2rem;
  }

  &.disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    z-index: 2;
    pointer-events: none;
  }
`

const Heading = styled.h3`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  grid-column: 1 / -1;
`

const SFormikText = styled(FormikText)`
  grid-column: 1 / -1;
`

const InputHeader = styled.h5`
  color: ${p => p.theme.color.text};
  font-size: 13px;
  letter-spacing: 0;
  margin-bottom: 12px;
`

const FileWrapper = styled.div`
  grid-column: 1 / -1;
  max-width: 500px;
`
const Row = styled.div`
  grid-column: 1 / -1;
`

const FormControls = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  button {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${p => p.theme.layout.web} {
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-right: 1rem;
      margin-bottom: 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`
// #endregion

const CrForm = ({
  values: crValues,
  submit,
  costConstituents,
  timeOptions,
  costCategories,
  lossExpense,
  isSubmitting,
  uploadedFiles,
  deleteFile,
  currency,
  deleteCR,
}) => {
  const { user } = React.useContext(AuthContext)
  const [submitType, setSubmitType] = React.useState('save')

  const step1Disabled = !!crValues.id
  const step2Disabled = !user.isProjectManager
  const step3Disabled = !user.isCostManager

  const showApproveCosts =
    !step3Disabled && !crValues.costs_approved && !!crValues.id

  return (
    <Formik
      validationSchema={changeRequestSchema}
      initialValues={crValues}
      onSubmit={submit(submitType)}
      enableReinitialize={true}
    >
      {({ values, setValues }) => {
        return (
          <Form>
            <CorCard className={step1Disabled ? 'disabled' : ''}>
              <Heading>1.To Be Completed by Change Request Originator</Heading>
              <FormikSelect
                name="cost_constituent"
                label="Cost Constituent"
                options={costConstituents}
                readonly={step1Disabled}
              />

              <FileWrapper>
                <FormikFile
                  name="files"
                  label="File Uploads"
                  types={[
                    'image/jpeg',
                    'image/png',
                    'application/pdf',
                    'text/csv',
                  ]}
                  maxSize={10}
                  info="Only .jpg, .png, .pdf and .csv files. Maximum file size 10MB"
                  uploadedFiles={uploadedFiles}
                  handleDelete={deleteFile}
                  readonly={step1Disabled}
                >
                  Add File
                </FormikFile>
              </FileWrapper>

              <SFormikText
                name="change"
                label="Proposed Change (Sent to client)"
                readonly={step1Disabled}
              />
              <SFormikText
                name="reason"
                label="Reason for Change (Sent to client)"
                rows={2}
                readonly={step1Disabled}
              />
              <SFormikText
                name="additional_comments"
                label="Additional comments (Not sent to client)"
                rows={2}
                readonly={step1Disabled}
              />
            </CorCard>

            <CorCard className={step2Disabled ? 'disabled' : ''}>
              <Heading>2.To Be Completed by the Contract Manager</Heading>

              <Row>
                <InputHeader>Programme Implications</InputHeader>
                <FormikCheckbox
                  name="implications"
                  label="No Programme Implications Anticipated"
                  readonly={step2Disabled}
                  onChange={() => {
                    if (!values.implications) {
                      setValues({ ...values, extension: '', matter: '' }, false)
                    }
                  }}
                />
              </Row>

              <FormikSelect
                name="extension"
                label="Anticipated Delay"
                options={timeOptions}
                readonly={step2Disabled || !!values.implications}
              />
              <FormikSelect
                name="matter"
                label="Anticipated Relevant Matter for Loss &amp; Expense"
                options={lossExpense}
                readonly={step2Disabled || !!values.implications}
              />
              <SFormikText
                name="comments"
                label="Additional comments (Not sent to client)"
                rows={2}
                readonly={step2Disabled}
              />
            </CorCard>

            <CorCard className={step3Disabled ? 'disabled' : ''}>
              <Heading>3. To Be Completed by the Cost Manager</Heading>
              {showApproveCosts ? (
                <FormikCheckbox
                  name="costs_approved"
                  label="Approve Costs"
                  readonly={step3Disabled}
                />
              ) : null}
              <Row>
                <CheckboxWithLabel
                  name="no associated costs"
                  label="No associated costs"
                  readonly={step3Disabled}
                  onChange={value => {
                    const noAssociatedCosts = value
                    const noCostsRow = {
                      id: '',
                      cost_category: 'None',
                      description: 'No associated costs',
                      quantity: 0,
                      unit: '-',
                      rate: 0,
                      total: 0,
                    }

                    setValues({
                      ...values,
                      cost_breakdown: noAssociatedCosts ? [noCostsRow] : [],
                    })
                  }}
                />
              </Row>
              <Row>
                <Costs
                  costCategories={costCategories}
                  currency={currency}
                  canEdit={!step3Disabled}
                />
              </Row>
            </CorCard>

            <FormControls>
              {user.isProjectManager && crValues?.id ? (
                <Button
                  type="button"
                  disabled={isSubmitting}
                  onClick={deleteCR}
                  style={{ background: 'darkred' }}
                >
                  Delete
                </Button>
              ) : null}
              <Button
                type="submit"
                disabled={isSubmitting}
                onClick={() => setSubmitType('save')}
              >
                Save
              </Button>
              {user.isProjectManager ? (
                <Button
                  type="submit"
                  disabled={
                    !values.costs_approved ||
                    !values.cost_breakdown ||
                    isSubmitting
                  }
                  onClick={() => setSubmitType('send')}
                >
                  Send For Client Approval
                </Button>
              ) : null}
            </FormControls>
          </Form>
        )
      }}
    </Formik>
  )
}

CrForm.propTypes = {
  values: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  timeOptions: PropTypes.array.isRequired,
  costConstituents: PropTypes.array.isRequired,
  costCategories: PropTypes.array.isRequired,
  lossExpense: PropTypes.array.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  deleteFile: PropTypes.func.isRequired,
  deleteCR: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
}

export default CrForm
