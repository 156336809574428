import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Form } from '../../../form'
import { Card, CardHeading, Table, Button } from '../../../ui'
import { formatCurrency } from '../../../../utilities'
import Costs from '../crForm/costs'
import { useAuth } from '../../../../hooks'

//#region styles
const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  background: white;
`

const Tab = styled.button`
  min-height: 32px;
  display: flex;
  align-items: center;
  width: fit-content;
  text-transform: uppercase;
  margin: 0 0.5rem 1rem 1rem;
  outline-offset: 0.5rem;
  cursor: pointer;
  color: #626261;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  border: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 200ms, color 200ms;
  background: none;

  &:disabled {
    cursor: not-allowed;
  }

  &[data-current='true'] {
    color: ${p => p.theme.color.primary};
    border-color: ${p => p.theme.color.primary};
  }

  ${p => p.theme.layout.web} {
    margin: 0 2rem 1.5rem 0;
    padding-bottom: 1rem;
  }
`

const STable = styled(Table)`
  th {
    text-align: start;
  }
`

const TdNoHeading = styled.td`
  &::before {
    display: none !important;
  }
`
const TotalText = styled.h4`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin: 0.5rem 0;
`
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TableWrapper = styled.div`
  margin: 0 1rem;
  ${p => p.theme.layout.web} {
    margin: 0;
  }
`
//#endregion

const CostAssessment = ({
  budgetCosts,
  assessedCosts,
  assessedCostIsDisabled,
  costCategories,
  saveCosts,
  isSubmitting,
  currency,
}) => {
  const { user } = useAuth()
  const [currentTable, setCurrentTable] = React.useState('budget')

  const switchTable = to => {
    if (currentTable !== to) setCurrentTable(to)
  }

  const symbol = currency === 'eur' ? '€' : '£'
  const HEADERS = [
    '',
    'Cost Category',
    'Description',
    'Qty',
    'Unit',
    `Rate (${symbol})`,
    `Total (${symbol})`,
    '',
  ]

  const canEditCosts = !!user?.isCostManager

  const saveAsssessedCosts = values => {
    saveCosts(values.cost_breakdown)
  }
  return (
    <Card style={{ flexBasis: '100%' }}>
      <CardHeading>Cost Assessment</CardHeading>

      <Nav>
        <Tab
          data-current={currentTable === 'budget'}
          onClick={() => switchTable('budget')}
          onMouseDown={e => e.preventDefault()}
          type="button"
        >
          Budget
        </Tab>

        <Tab
          data-current={currentTable === 'assessed'}
          onClick={() => switchTable('assessed')}
          onMouseDown={e => e.preventDefault()}
          type="button"
          disabled={assessedCostIsDisabled}
        >
          Assessed Cost
        </Tab>
      </Nav>

      <TableWrapper>
        <STable
          table
          headers={HEADERS}
          style={{ display: currentTable === 'budget' ? 'table' : 'none' }}
        >
          {budgetCosts.map((cost, i) => (
            <tr key={cost.id}>
              <td data-header={HEADERS[0]} style={{ width: '2rem' }}>
                {i + 1}.
              </td>
              <td data-header={HEADERS[1]}>{cost.cost_category}</td>
              <td data-header={HEADERS[2]} style={{ columnWidth: '260px' }}>
                {cost.description}
              </td>
              <td data-header={HEADERS[3]}>{cost.quantity}</td>
              <td data-header={HEADERS[4]}>{cost.unit}</td>
              <td data-header={HEADERS[5]}>{formatCurrency(cost.rate)}</td>
              <td data-header={HEADERS[6]}>{formatCurrency(cost.total)}</td>
              <td data-header={HEADERS[7]} style={{ width: '50px' }}></td>
            </tr>
          ))}
          <tr>
            <TdNoHeading colSpan={8} style={{ ':before': { display: 'none' } }}>
              <Row>
                <TotalText style={{ marginLeft: 'auto' }}>
                  Total: {symbol}
                  {formatCurrency(
                    budgetCosts.reduce((total, c) => total + +c.total, 0) || 0,
                  )}
                </TotalText>
              </Row>
            </TdNoHeading>
          </tr>
        </STable>
        <Formik
          validationSchema={Yup.object().shape({
            cost_breakdown: Yup.array().of(
              Yup.object().shape({
                id: Yup.string(),
                cost_category: Yup.string().required(),
                description: Yup.string().required(),
                quantity: Yup.number().required(),
                unit: Yup.string().required(),
                rate: Yup.number().required(),
                total: Yup.number().required(),
              }),
            ),
          })}
          initialValues={{ cost_breakdown: assessedCosts }}
          onSubmit={saveAsssessedCosts}
          isSubmitting={isSubmitting}
        >
          <Form
            style={{ display: currentTable === 'assessed' ? 'block' : 'none' }}
          >
            <Costs
              costCategories={costCategories}
              canEdit={canEditCosts}
              currency={currency}
            />
            {canEditCosts ? (
              <Button
                type="submit"
                small
                style={{ marginTop: '1rem' }}
                disabled={isSubmitting}
              >
                Save Assessed Costs
              </Button>
            ) : null}
          </Form>
        </Formik>
      </TableWrapper>
    </Card>
  )
}

CostAssessment.defaultProps = {
  assessedCosts: [],
  budgetCosts: [],
}

CostAssessment.propTypes = {
  assessedCosts: PropTypes.arrayOf(PropTypes.shape({})),
  assessedCostIsDisabled: PropTypes.bool.isRequired,
  budgetCosts: PropTypes.arrayOf(PropTypes.shape({})),
  costCategories: PropTypes.array.isRequired,
  saveCosts: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
}
export default CostAssessment
