import React from 'react'
import PropTypes from 'prop-types'
import { differenceInCalendarDays } from 'date-fns'

import { CardWrapper } from '../../../ui'
import Timeline from './timeline'
import Details from './details'
import CostAssessment from './costAssessment'
import { COST_CAT_OPTIONS } from '../../../../constants'
import { useAuth } from '../../../../hooks'
import AcceptReject from './acceptReject'

const Change = ({
  cr,
  createNote,
  saveCosts,
  isSubmitting,
  uploadedFiles,
  updatePublishedCR,
  deleteCR,
  approveRejectCR,
  resendCrToClient,
  currency,
}) => {
  const { user } = useAuth()

  const timeline = cr.events.map(event => ({
    id: event.id,
    cr_id: event.change_request_id,
    date: event.created_at,
    type: event.type,
    note: {
      title: event.note_title,
      text: event.note_description,
    },
    user: event.user_name || event.user_id,
  }))

  const details = {
    id: cr.id,
    cr_no: cr.cr_no,
    status: cr.status,

    date_raised: cr.date_raised,
    cost_constituent: cr.cost_constituent,
    change: cr.change,
    reason: cr.reason,
    delay: cr.extension,
    anticipated_matter: cr.matter,
    files: cr.files,
    reported_cost: cr.cost_breakdown.reduce((total, c) => total + +c.total, 0),

    rfi: cr.rfi,
    client_decision_date: cr.client_decision_date,

    budget_cost: cr.budget_cost,
    gc_claimed_cost: cr.gc_claimed_cost,
    date_gc_claimed_cost: cr.date_gc_claimed_cost,
    assessed_cost: cr.assessed_cost,
    reported_cost_values: cr.reported_cost_values,

    instruction_number: cr.instruction_number,
    instruction_date: cr.instruction_date,
    days_since_instruction: cr.instruction_date
      ? `${differenceInCalendarDays(
          new Date(),
          new Date(cr.instruction_date),
        )} days`
      : '-',

    originator_ref: cr.originator_ref,
  }

  const budgetCosts = cr.cost_breakdown.map(c => ({
    id: c.id,
    cost_category:
      COST_CAT_OPTIONS.find(cat => cat.value === c.cost_category)?.label ||
      'Unknown',
    description: c.description,
    quantity: c.quantity,
    unit: c.unit,
    rate: c.rate,
    total: c.total,
  }))

  const assessedCosts = cr.assessed_costs.length
    ? cr.assessed_costs.map(c => ({
        id: c.id,
        cost_category: c.cost_category,
        description: c.description,
        quantity: +c.quantity,
        unit: c.unit,
        rate: +c.rate,
        total: c.total,
      }))
    : cr.cost_breakdown.map(c => ({
        cost_category: c.cost_category,
        description: c.description,
        quantity: +c.quantity,
        unit: c.unit,
        rate: +c.rate,
        total: c.total,
      }))

  return (
    <CardWrapper>
      <div style={{ flex: 1 }}>
        <AcceptReject cr={cr} approveRejectCR={approveRejectCR} />
        <Timeline events={timeline} createNote={createNote} />
      </div>

      <div style={{ flex: 1 }}>
        <Details
          cr={details}
          uploadedFiles={uploadedFiles}
          updatePublishedCR={updatePublishedCR}
          deleteCR={deleteCR}
          isSubmitting={isSubmitting}
          currency={currency}
          resend={() => resendCrToClient(cr.id)}
        />
      </div>

      {user.isCostManager ||
      user.isProjectManager ||
      user.user_group === 'client' ? (
        <CostAssessment
          budgetCosts={budgetCosts}
          assessedCosts={assessedCosts}
          assessedCostIsDisabled={!cr.gc_claimed_cost}
          costCategories={COST_CAT_OPTIONS}
          saveCosts={saveCosts}
          isSubmitting={isSubmitting}
          currency={currency}
        />
      ) : null}
    </CardWrapper>
  )
}

Change.propTypes = {
  cr: PropTypes.any.isRequired,
  createNote: PropTypes.func.isRequired,
  saveCosts: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  updatePublishedCR: PropTypes.func.isRequired,
  deleteCR: PropTypes.func.isRequired,
  approveRejectCR: PropTypes.func.isRequired,
  resendCrToClient: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
}
export default Change
