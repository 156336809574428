export const USER_TYPE_OPTIONS = [
  {
    value: 'contract_manager',
    label: 'Contract Manager',
  },
  {
    value: 'cost_manager',
    label: 'Cost Manager',
  },
  {
    value: 'designer',
    label: 'Designer',
  },
  {
    value: 'client',
    label: 'Client',
  },
]

export const COST_CONSTITUENT_OPTIONS = [
  { value: 'construction', label: 'Construction' },
  { value: 'fees', label: 'Fees & Surveys' },
  { value: 'other', label: 'Other Project Costs' },
]

export const CONFIRM_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'TBC', label: 'TBC' },
]

export const COST_CAT_OPTIONS = [
  { value: 'Facilitating works', label: 'Facilitating works' },
  { value: 'Substructure', label: 'Substructure' },
  { value: 'Frame', label: 'Frame' },
  { value: 'Upper floors', label: 'Upper floors' },
  { value: 'Roof', label: 'Roof' },
  { value: 'Stairs and ramps', label: 'Stairs and ramps' },
  { value: 'External walls', label: 'External walls' },
  { value: 'Windows and external doors', label: 'Windows and external doors' },
  {
    value: 'Internal walls and partitions',
    label: 'Internal walls and partitions',
  },
  { value: 'Internal doors', label: 'Internal doors' },
  { value: 'Wall finishes', label: 'Wall finishes' },
  { value: 'Floor finishes', label: 'Floor finishes' },
  { value: 'Ceiling finishes', label: 'Ceiling finishes' },
  {
    value: 'Fittings, furnishings and equipment',
    label: 'Fittings, furnishings and equipment',
  },
  { value: 'Services - Public Health', label: 'Services - Public Health' },
  { value: 'Services - Mechanical', label: 'Services - Mechanical' },
  { value: 'Services - Electrical', label: 'Services - Electrical' },
  { value: 'Services - Other', label: 'Services - Other' },
  { value: 'External works', label: 'External works' },
  {
    value: 'Main contractor preliminaries',
    label: 'Main contractor preliminaries',
  },
  {
    value: 'Main contractor oh&p',
    label: 'Main contractor oh&p',
  },
  {
    value: 'Construction contract contingency',
    label: 'Construction contract contingency',
  },
  { value: 'Construction – other', label: 'Construction – other' },
  { value: 'Project fees', label: 'Project fees' },
  { value: 'Other project costs', label: 'Other project costs' },
  { value: 'None', label: 'None' },
]

export const TIME_OPTIONS = () => {
  const days = new Array(100).fill(null).map((day, index) => {
    return {
      value: `${index + 1 === 1 ? index + 1 + ' day' : index + 1 + ' days'}`,
      label: `${index + 1 === 1 ? index + 1 + ' day' : index + 1 + ' days'}`,
    }
  })
  const months = new Array(24).fill(null).map((month, index) => {
    return {
      value: `${
        index + 1 === 1 ? index + 1 + ' month' : index + 1 + ' months'
      }`,
      label: `${
        index + 1 === 1 ? index + 1 + ' month' : index + 1 + ' months'
      }`,
    }
  })
  return [...days, ...months]
}
