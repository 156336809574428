import styled from 'styled-components'

export { default as Form } from './formHelper'
export { InputWithLabel, FormikInput, FormikTableInput } from './input'
export { Select, FormikSelect, FormikTableSelect } from './select'
export { default as FormikCombo } from './combo'
export { CheckboxWithLabel, FormikCheckbox } from './checkbox'
export { TextareaWithLabel, FormikText } from './textarea'
export { FormikFile } from './file'
export { Search } from './search'

export const InputWrapper = styled.div.attrs(props => ({
  className: [
    props.hasError ? 'has-error' : '',
    props.readonly ? 'isReadonly' : '',
  ],
}))`
  position: relative;
  display: flex;
  padding-top: 1rem;
  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    border-bottom: 2px solid
      ${p => (p.hasError ? p.theme.color.error : 'transparent')};
    transition: border-color 150ms ease-in-out;
  }

  &.isReadonly {
    &:focus-within {
      &::after {
        border-bottom-color: transparent;
      }
    }
    input {
      user-select: none;
      border-bottom-color: transparent;
      background: transparent;
    }
  }

  /* hide browser focus and provide alternative */
  input {
    outline: none;
  }
  &:focus-within {
    &:after {
      border-bottom: 2px solid ${p => p.theme.color.focus};
    }
  }

  input::placeholder {
    visibility: hidden;
    opacity: 0;
  }
  &.isFilled label {
    transform: translateY(-1.2rem);
    font-size: 13px;
    pointer-events: all;
  }
`

export const Label = styled.label`
  position: absolute;
  pointer-events: none;
  color: ${p => p.theme.color.text};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  bottom: 0.5rem;
  z-index: 2;
  transition: transform 150ms ease-in-out, font-size 150ms ease-in-out;
`

export const Error = styled.div`
  min-height: 1.2rem;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.2rem;
  color: ${p => p.theme.color.error};
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
