import React from 'react'
import styled from 'styled-components'
import { FormikInput, FormikSelect } from '../../form'
import { Address } from '../../form/address'

const FormGroup = styled.div`
  background: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: ${p => p.theme.shadow};

  ${p => p.theme.layout.web} {
    padding: 2rem;
  }
`

const Header = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 2rem;
`

const Subheader = styled.h3`
  font-size: 16px;
  font-weight: bold;
  grid-row: -1;
  line-height: 16px;
  margin-bottom: 1.25rem;
`

const Description = styled.p`
  color: #757575;
  font-size: 14px;
  line-height: 1.5rem;
  max-width: 420px;
`

const Grid = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 0 2rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-rows: repeat(auto-fit);
  margin: 1rem 0;
`

const currency = [
  {
    label: 'GBP',
    value: 'gbp',
  },
  {
    label: 'EUR',
    value: 'eur',
  },
]

export const contractType = [
  {
    label: 'Traditional',
    value: 'traditional',
  },
  {
    label: 'Design and Build',
    value: 'design_and_build',
  },
  {
    label: 'GMP',
    value: 'gmp',
  },
  {
    label: 'Management Contract',
    value: 'management_contract',
  },
  {
    label: 'Prime Cost',
    value: 'prime_cost',
  },
]

export const measurementUnits = [
  {
    label: 'Gross Internal Area (m2)',
    value: 'area.gross_internal',
  },
  {
    label: 'Net Internal Area (m2)',
    value: 'area.net_internal',
  },
]

const ProjectsDetails = props => {
  return (
    <FormGroup>
      <Header>1 - Project Details</Header>

      <Grid>
        <FormikInput type="text" name="dashboard.title" label="Project Title" />
      </Grid>

      <Address title="Site Address" namespace="site" />

      <Subheader>Area Measurements</Subheader>
      <Grid>
        <FormikInput
          type="text"
          name="area.gross_internal"
          label="Gross Internal Area (m2)"
          {...props}
        />
        <FormikInput
          type="text"
          name="area.net_internal"
          label="Net Internal Area (m2)"
          {...props}
        />
        <FormikInput
          type="text"
          name="area.gross_external"
          label="Gross External Area (m2)"
          {...props}
        />
        <FormikInput
          type="text"
          name="area.perimeters"
          label="Total Perimeters (m)"
          {...props}
        />
        <FormikInput
          type="text"
          name="area.typical_floor"
          label="Typical Floor to Ceiling Height (m)"
          {...props}
        />
      </Grid>

      <Subheader>Project Finances</Subheader>
      <Grid>
        <FormikSelect
          name="currency.selected"
          label="Currency"
          options={currency}
        />

        <FormikInput
          type="text"
          name="currency.exchange_rate"
          label="Exchange Rate"
          currency
        />

        <FormikSelect
          name="contract.type"
          label="Contract Type"
          options={contractType}
        />
      </Grid>

      <Subheader>Measurement Calculations</Subheader>
      <Description style={{ maxWidth: 'unset' }}>
        The unit of measurement used for m2 calculations in Starting Point Costs
      </Description>
      <Grid style={{ marginBottom: '0px' }}>
        <FormikSelect
          name="measurement.unit"
          label="Unit of measurement"
          options={measurementUnits}
        />
      </Grid>
    </FormGroup>
  )
}

export default ProjectsDetails
