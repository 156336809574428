import axios from 'axios'

import { formatError } from './utils'

const addToFormData = (formData, values, key) => {
  if (!key) throw new Error('Missing key in addToFormData')
  Object.entries(values).forEach(([k, v]) => {
    formData.append(`${key}[${k}]`, v ?? '')
  })
}

export const submitProject = async (values, edit) => {
  const formData = new FormData()

  // area
  addToFormData(formData, values.area, 'area')
  // budget
  formData.append(`budget[total]`, values.budget.total)
  // formData.append(`budget[additional][total]`, values.budget.additional.total)
  // change request
  Object.entries(values.change_request).forEach(([key, value]) =>
    formData.append(`change_request[${key}]`, value.time),
  )
  // client
  const { details, ...client } = values.client
  formData.append(`client[details][name]`, details.name)
  Object.entries(client).forEach(([key, value]) =>
    formData.append(`client[${key}]`, value),
  )
  // contract
  formData.append('contract[type]', values.contract.type)
  // change request
  Object.entries(values.change_request).forEach(([key, value]) => {
    formData.append(`change_request[${key}][time]`, value.time)
  })
  // costs
  Object.entries(values.cost).forEach(([key, value]) => {
    const isRisk = [
      'design_dev',
      'construction',
      'employer_cr',
      'employer_other',
    ].includes(key)
    if (isRisk) formData.append(`cost[${key}][total]`, value.total || 0)
    else formData.append(`cost[${key}][total]`, value.total)
  })
  // other costs
  Object.entries(values.other_costs).forEach(([key, otherCostsInSection]) => {
    otherCostsInSection.forEach((cost, idx) => {
      formData.append(`other_costs[${key}][${idx}][type]`, cost.type)
      formData.append(`other_costs[${key}][${idx}][total]`, cost.total)
    })
  })

  // currency
  Object.entries(values.currency).forEach(([key, value]) =>
    formData.append(`currency[${key}]`, value),
  )
  // dashboard title
  formData.append('dashboard[title]', values.dashboard.title)
  // measurement
  formData.append('measurement[unit]', values.measurement.unit)

  // risk
  const riskAllowance = [
    'design_dev',
    'construction',
    'employer_cr',
    'employer_other',
  ].reduce((total, risk) => {
    const parsedRisk = parseFloat(values.cost[risk]?.total?.replace(/,/g, ''))
    if (Number.isNaN(parsedRisk)) return total
    return total + parsedRisk
  }, 0)
  if (riskAllowance) formData.append(`risk[allowance][total]`, riskAllowance)

  // site
  Object.entries(values.site).forEach(([key, value]) =>
    formData.append(`site[${key}]`, value),
  )

  // project clients
  values.clients &&
    (values.clients || []).forEach((member, i) =>
      addToFormData(formData, member, `clients[${i}]`),
    )

  // project team
  values.team &&
    (values.team || []).forEach((member, i) =>
      addToFormData(formData, member, `project_team[${i}]`),
    )

  Object.entries(values.site).forEach(([key, value]) =>
    formData.append(`site[${key}]`, value),
  )

  const url = edit ? `project/${values.id}` : 'project'

  try {
    const res = await axios({
      method: 'post',
      url,
      data: formData,
    })
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    if (axios.isCancel(e)) return { cancelled: true }
    else
      return formatError(
        e,
        `Project could not be ${edit ? 'updated' : 'created'}`,
      )
  }
}
