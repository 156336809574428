import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useAuth } from '../../../../../hooks'
import ClaimedCost from './claimedCost'
import ClaimedCostDate from './claimedCostDate'
import ReportedCost from './reportedCost'
import UpdateForm from './update-form'
import { VerticalTable, VTCollapsible, Status, Button } from '../../../../ui'
import { File } from './ui'
import { getShortDate } from '../../../../../utilities'
import {
  COST_CAT_OPTIONS,
  COST_CONSTITUENT_OPTIONS,
  CONFIRM_OPTIONS,
} from '../../../../../constants'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`
const Head = styled.h3`
  display: inline-block;
  color: ${p => p.theme.color.text};
  font-size: 14px;
  letter-spacing: 0;
  margin-right: 15px;
`

const Details = ({
  cr,
  uploadedFiles,
  updatePublishedCR,
  deleteCR,
  isSubmitting,
  currency,
  resend,
}) => {
  const { gc_claimed_cost } = cr
  const { user } = useAuth()

  const options = {
    costCategories: COST_CAT_OPTIONS,
    costConstituents: COST_CONSTITUENT_OPTIONS,
  }

  const symbol = currency === 'eur' ? '€' : '£'
  const canEdit = user.isCostManager || user.isProjectManager
  const canEditClaimedCost =
    ['approved', 'formalised'].includes(cr.status) && canEdit
  const canEditReportedCosts = canEdit
  const canResendForClientApproval =
    cr.status === 'pending' && user.user_group !== 'client'
  return (
    <VerticalTable
      inCard
      heading={
        <Row style={{ flex: 1 }}>
          <Row style={{ flex: 1 }}>
            <h1 style={{ marginRight: '1rem' }}>Change Request #{cr.cr_no}</h1>
            {canEdit && (
              <UpdateForm
                cr={cr}
                updatePublishedCR={updatePublishedCR}
                deleteCR={deleteCR}
                isSubmitting={isSubmitting}
                canEditClaimedCost={canEditClaimedCost}
                {...options}
              />
            )}
            <Row>
              <Head>Status:</Head>
              <Status status={cr.status} />
            </Row>
          </Row>
          {canResendForClientApproval ? (
            <Row style={{ flex: '1 1 100%' }}>
              <Button
                style={{ marginTop: '1rem' }}
                onClick={() => resend(cr.id)}
              >
                Resend for client approval
              </Button>
            </Row>
          ) : null}
        </Row>
      }
    >
      <tr>
        <th>Originator Ref</th>
        <td>{cr.originator_ref}</td>
      </tr>
      <tr>
        <th>Date Raised</th>
        <td>{getShortDate(cr.date_raised)}</td>
      </tr>
      <tr>
        <th>Client Decision Date</th>
        <td>
          {cr.client_decision_date
            ? getShortDate(cr.client_decision_date)
            : '-'}
        </td>
      </tr>
      <VTCollapsible heading="Instruction details">
        <tr>
          <th>Instruction Number</th>
          <td>{cr.instruction_number}</td>
        </tr>
        <tr>
          <th>Instruction Date</th>
          <td>{getShortDate(cr.instruction_date)}</td>
        </tr>
        <tr>
          <th>Days Since Instruction Issued</th>
          <td>{cr.days_since_instruction}</td>
        </tr>
      </VTCollapsible>
      <tr>
        <th>Cost Constituent</th>
        <td>
          {COST_CONSTITUENT_OPTIONS.find(
            cat => cat.value === cr.cost_constituent,
          )?.label || 'Unknown'}
        </td>
      </tr>
      <tr>
        <th>Proposed Change</th>
        <td>{cr.change}</td>
      </tr>
      <tr>
        <th>Reason for Change</th>
        <td>{cr.reason}</td>
      </tr>
      <tr>
        <th>Anticipated delay</th>
        <td>{cr.delay}</td>
      </tr>
      <tr>
        <th>Anticipated Relevant Matter for Loss &amp; Expense</th>
        <td>
          {CONFIRM_OPTIONS.find(cat => cat.value === cr.anticipated_matter)
            ?.label || '-'}
        </td>
      </tr>
      <tr>
        <th>Files</th>
        <td>
          {uploadedFiles.map(file => (
            <File
              key={file.id}
              href={file.url}
              rel="noreferrer noopener"
              target="_blank"
            >
              {file.filename}
            </File>
          ))}
        </td>
      </tr>
      <tr>
        <th>Reported Cost Values</th>
        <td>
          <ReportedCost
            cr={cr}
            isSubmitting={isSubmitting}
            symbol={symbol}
            canEdit={canEditReportedCosts}
            update={updatePublishedCR}
          />
        </td>
      </tr>
      <tr>
        <th>Contractor Claimed Cost</th>
        <td>
          <ClaimedCost
            cost={gc_claimed_cost}
            update={updatePublishedCR}
            isSubmitting={isSubmitting}
            canEdit={canEditClaimedCost}
          />
        </td>
      </tr>
      <tr>
        <th>Contractor Claimed Cost Date</th>
        <td>
          <ClaimedCostDate
            date={cr.date_gc_claimed_cost}
            update={updatePublishedCR}
            isSubmitting={isSubmitting}
            canEdit={canEditClaimedCost}
          />
        </td>
      </tr>
    </VerticalTable>
  )
}

Details.propTypes = {
  cr: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    date_raised: PropTypes.string,
    cr_no: PropTypes.string,

    cost_constituent: PropTypes.string,
    change: PropTypes.string,
    reason: PropTypes.string,
    delay: PropTypes.string,
    anticipated_matter: PropTypes.string,
    files: PropTypes.array,
    reported_cost: PropTypes.number,
    date_gc_claimed_cost: PropTypes.string,
    gc_claimed_cost: PropTypes.string,
    rfi: PropTypes.string,
    client_decision_date: PropTypes.string,

    instruction_number: PropTypes.string,
    instruction_date: PropTypes.string,
    days_since_instruction: PropTypes.string,

    budget_cost: PropTypes.string,
    assessed_cost: PropTypes.string,
    reported_cost_values: PropTypes.string,

    originator_ref: PropTypes.string,
    originator_comments: PropTypes.string,

    master_cr: PropTypes.string,
    pm_comments: PropTypes.string,
  }).isRequired,
  updatePublishedCR: PropTypes.func.isRequired,
  deleteCR: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  resend: PropTypes.func.isRequired,
}
export default Details
