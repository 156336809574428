import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Bar, Pie } from 'react-chartjs-2'
import styled from 'styled-components'
import { formatCurrency } from '../../utilities'

import useGetDash from './useGetDash'
import { useAuth } from '../../hooks'
import {
  Loader,
  View,
  ViewHeader,
  Button,
  CardWrapper,
  Card,
  Table,
  TableHeading,
} from '../ui'

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 4rem);
  margin-bottom: 2rem;
  min-height: 300px;

  ${p => p.theme.layout.web} {
    max-width: calc(100% - 1px);
    min-height: 475px;
  }
`

const PiesContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr);
  grid-auto-rows: 300px;
  gap: 2rem;
  ${p => p.theme.layout.web} {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: 200px;
  }
`
const PieWrapper = styled.div`
  position: relative;
  height: 100%;
  p {
    text-align: center;
  }
`

const Legend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
`

const LegendItem = styled.li`
  position: relative;
  margin-left: 2.5rem;
  margin-right: 1rem;
  color: #000000;
  font-family: Arial;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 13px;

  &:before {
    content: '';
    position: absolute;
    left: -32px;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 4px;
    background: ${p => p.color};
  }
`

const HEADERS = [
  'Name',
  'Area m2',
  'Total Cost',
  'Total Cost/m2',
  'Construction Cost/m2',
  'Fees as % of Construction',
]

const PIE_COLORS = ['#FF073A', '#20CA14', '#13800C']

const Dashboard = () => {
  const { isFetching, data } = useGetDash()
  const { user } = useAuth()
  const navigate = useNavigate()

  return (
    <View>
      <ViewHeader>
        <h1>Dashboard</h1>
        {user.isCostManager ? (
          <Button onClick={() => navigate(`/dashboard/new`)}>
            Create New Project
          </Button>
        ) : null}
      </ViewHeader>
      <Loader isFetching={isFetching}>
        <CardWrapper>
          <Card style={{ overflow: 'scroll' }}>
            <TableHeading large>1. Project Total costs</TableHeading>
            <ChartWrapper>
              {data ? (
                <Bar
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      xAxes: [{ stacked: true }],
                      yAxes: [{ stacked: true }],
                    },
                  }}
                  data={{
                    labels: data.projects.map(project => project.name),
                    datasets: [
                      {
                        label: 'Construction',
                        backgroundColor: '#0086CB',
                        data: data.projects.map(p => p.construction),
                        maxBarThickness: 125,
                      },
                      {
                        label: 'Fees',
                        backgroundColor: '#20CA14',
                        data: data.projects.map(p => p.fees),
                        maxBarThickness: 125,
                      },
                      {
                        label: 'Other Project Costs',
                        backgroundColor: '#FF073A',
                        data: data.projects.map(p => p.other),
                        maxBarThickness: 125,
                      },
                      {
                        label: 'Risk',
                        backgroundColor: '#6EC2DA',
                        data: data.projects.map(p => p.risk),
                        maxBarThickness: 125,
                      },
                    ],
                  }}
                />
              ) : null}
            </ChartWrapper>
            <div style={{ overflow: 'scroll' }}>
              <Table
                headers={HEADERS}
                pdfData={data?.projects}
                pdfFile="total_cost"
              >
                {data?.projects.map(project => (
                  <tr key={project.id}>
                    <td data-header={HEADERS[0]}>{project.name}</td>
                    <td data-header={HEADERS[2]}>
                      {formatCurrency(project.area ? project.area : 0, {
                        showSign: false,
                      })}
                    </td>
                    <td data-header={HEADERS[3]}>
                      {formatCurrency(project.total_cost, {
                        symbol: project.currency,
                        showSign: false,
                      })}
                    </td>
                    <td data-header={HEADERS[4]} style={{ fontWeight: 600 }}>
                      {formatCurrency(project.total_per_m2, {
                        showSign: false,
                      })}
                    </td>
                    <td data-header={HEADERS[5]}>
                      {formatCurrency(project.construction_m2, {
                        showSign: false,
                      })}
                    </td>
                    <td data-header={HEADERS[6]}>{project.fee_percent}%</td>
                  </tr>
                ))}
              </Table>
            </div>
          </Card>
          <Card style={{ flexBasis: '100%', paddingBottom: '3rem' }}>
            <TableHeading large>
              2. changes vs remaining risk / budget
            </TableHeading>
            <Legend>
              <LegendItem color={PIE_COLORS[0]}>Changes</LegendItem>
              <LegendItem color={PIE_COLORS[1]}>
                Remaining Risk Allowance
              </LegendItem>
              <LegendItem color={PIE_COLORS[2]}>Remaining Budget</LegendItem>
            </Legend>
            <PiesContainer>
              {data?.changes_risk_remaining.map(project => (
                <PieWrapper key={project.id} name={project.name}>
                  <Pie
                    className="dash-pie"
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      legend: {
                        display: false,
                      },
                    }}
                    data={{
                      labels: ['C', 'RRA', 'RB'],
                      datasets: [
                        {
                          data: [
                            project.changes,
                            project.remaining_risk_allowance,
                            project.remaining_budget,
                          ],
                          backgroundColor: PIE_COLORS,
                        },
                      ],
                    }}
                  />
                  <p>{project.name}</p>
                </PieWrapper>
              ))}
            </PiesContainer>
          </Card>
        </CardWrapper>
      </Loader>
    </View>
  )
}

export default Dashboard
